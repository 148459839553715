import $ from 'jquery'
let alert_dialog = {
    title :"alert",
    msg : "Reason for payment failureReason for payment failureReason for payment failureReason for payment failure ",
    close: function (){
        $('#failedModal').hide();
    },
    open : function(msg) {
        let  alertDialogTitle= arguments.length>1?arguments[1]:'';
        let  callbackFunc= arguments.length>2?arguments[2]:function(){
            window.location.reload();
        };
        let closeFuc = function(){
            window.location.reload();
        };
        $('#failedModal').show();
        $('#failedModal .modal-body>p').html(msg);
        $('#failedModalLabel').html(alertDialogTitle);
        $('#failedModal .modal-footer>button').click(callbackFunc);
        $('#failedModal .close').click(closeFuc);
    },
    payFailed : function () {
        $('#failedModal').show();
    },
    paymentFailed : function () {
        $('#failedModal .modal-content').html(`
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
            <div class="modal-body">
                <img src="/assets/images/icon/pay_failed.png" alt="">
                <span>Transaction failed</span>
            </div>
            <div class="modal-footer">
                <a href="/member/myorder">
                    <button type="button" class="btn btn-normal " data-dismiss="modal">View Order Details</button>
                </a>
            </div>`);
        let closeFuc = function(){
            window.location.reload();
        };
        $('#failedModal .close').click(closeFuc);
        $('#failedModal').show();
    },
    paymentWait : function () {
        $('#failedModal .modal-content').html(`
             <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
            <div class="modal-body">
                <img src="/assets/images/icon/pay_wait.png" alt="">
                <span>Transaction in progress</span>
                <p class="info-a">Please wait a moment</p>
            </div>
            <div class="modal-footer">
                <a href="/member/myorder">
                    <button type="button" class="btn btn-normal " data-dismiss="modal">View Order Details</button>
                </a>
            </div>`);
        let closeFuc = function(){
            window.location.href = "/member/myorder";
        };
        $('#failedModal .close').click(closeFuc);
        $('#failedModal').show();
    }
};

export default alert_dialog;
