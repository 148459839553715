import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Transaction from './sections/transactionSection'
import Disconnect from './sections/disconnectSection'
import SecConnect from './sections/connectSection'
import reportWebVitals from './reportWebVitals';
import DisconnectNotReload from './sections/disconnectNotReloadSection'

const connect = document.getElementById('connect');
if (connect) {
    const rootElement = ReactDOM.createRoot(connect);
    rootElement.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}


const disconnect = document.getElementById('disconnect');
if (disconnect) {
    const disconnectElement = ReactDOM.createRoot(disconnect);
    disconnectElement.render(
        <React.StrictMode>
            <Disconnect />
        </React.StrictMode>
    );
}

const disconnect_not_reload = document.getElementById('disconnect_not_reload');
if (disconnect_not_reload) {
    const disconnectElement = ReactDOM.createRoot(disconnect_not_reload);
    disconnectElement.render(
        <React.StrictMode>
            <DisconnectNotReload />
        </React.StrictMode>
    );
}


const transaction = document.getElementById('transaction');
if (transaction) {
    const transactionElement = ReactDOM.createRoot(transaction);
    transactionElement.render(
        <React.StrictMode>
            <Transaction />
        </React.StrictMode>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
