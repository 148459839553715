import {ethers} from 'ethers';
import {useState, useEffect} from 'react';
import web3Modal from '../helper/connect';
import nftAxios from "../helper/axios";
import App from "../App";
import loadingMask from "../helper/loading";

function Disconnect(props) {
    const [provider, setProvider] = useState();
    const [library, setLibrary] = useState();
    const [account, setAccount, ] = useState();
    const [signature, setSignature] = useState("");
    const [error, setError] = useState("");
    const [chainId, setChainId] = useState();
    const [network, setNetwork] = useState();
    const [message, setMessage] = useState("");
    const [signedMessage, setSignedMessage] = useState("");
    const [verified, setVerified] = useState();
    const [to, setTo] = useState('')
    // //判断是否有钱包连接的缓存
    // useEffect(() => {
    //     if (web3Modal.cachedProvider) {
    //         connectWallet();
    //     }
    // }, []);
    //
    // const connectWallet = async () => {
    //     try {
    //         const provider = await web3Modal.connect();
    //
    //         const library = new ethers.providers.Web3Provider(provider);
    //         const accounts = await library.listAccounts();
    //         const network = await library.getNetwork();
    //         setProvider(provider);
    //         setLibrary(library);
    //         if (accounts) {
    //             setAccount(accounts[0])
    //         }
    //         setNetwork(network);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const disconnect = async () => {
        loadingMask.open();
        await web3Modal.clearCachedProvider();
        refreshState();
        loadingMask.close();
    };

    const refreshState = () => {
        setAccount();
        setChainId();
        setNetwork("");
        setMessage("");
        setSignature("");
        setVerified(undefined);
        setLibrary("")
        nftAxios("/login/logout").then(res => {
            if (res.data.result && res.data.result.code == 200) {
                window.location.href = "/"
            }
        });

    };

    return (
        <div className="App">
            <button onClick={disconnect}>disconnect</button>
        </div>
    );
}

export default Disconnect;
