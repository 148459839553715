import {ethers} from 'ethers';
import {useState, useEffect} from 'react';
import web3Modal from '../helper/connect';
import nftAxios from "../helper/axios";
import App from "../App";
import loadingMask from "../helper/loading";
import alert_dialog from "../helper/dialog";
import $ from 'jquery'

let is_error = false;
function Transaction(props) {
    const [provider, setProvider] = useState();
    const [library, setLibrary] = useState();
    const [account, setAccount] = useState();
    const [network, setNetwork] = useState();
    //判断是否有钱包连接的缓存
    useEffect(() => {
        if (web3Modal.cachedProvider) {
            connectWallet();
        }
    }, []);

    const connectWallet = async () => {
        try {
            const provider = await web3Modal.connect();

            const library = new ethers.providers.Web3Provider(provider);
            const accounts = await library.listAccounts();
            const network = await library.getNetwork();
            setProvider(provider);
            setLibrary(library);
            if (accounts) {
                setAccount(accounts[0])
            }
            setNetwork(network);
        } catch (error) {
            console.error(error);
        }
    };

    const transaction = async () => {
        try {
            if (library) {
                loadingMask.open();
                let txhash = '';
                var Web3 = require('web3');
                let web3 = new Web3(Web3.givenProvider);
                if (window.myether_provider != undefined) {
                    web3 = new Web3(window.myether_provider);
                }

                await library.provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: web3.utils.toHex(1) }],
                });
                // console.log(account)
                // web3.eth.sendTransaction({
                //     from: account,
                //     to: '0xB0d7c0f5ED8451Faf206f66Ed420835f4E7Eaa53',
                //     value: 100000000000,
                //     chain: 1
                // }).on('transactionHash', function(hash){
                //     $("#loading-mask-loader").append('<span class="" id="loading-text" style="width:280px;margin-top: 85px;margin-left: -85px;display: block">Transaction in progress...</span>');
                // }).on('receipt', function(receipt){
                //     if (!receipt.status && !is_error) {
                //         is_error = true;
                //         alert_dialog.paymentFailed();
                //     }
                // }).on('confirmation', function(confirmationNumber, receipt){
                //     console.log(confirmationNumber);
                //     if (confirmationNumber > 5 && !is_error) {
                //         alert_dialog.paymentWait();
                //     }
                // }).on('error', function(error) {
                //     loadingMask.close();
                //     console.log(error)
                // }); // If a out of gas error, the second parameter is the receipt.
                // return;

                let transaction = document.getElementById('transaction');
                let billing = transaction.getAttribute('billing')
                console.log(JSON.parse(billing))
                nftAxios("/checkout/postOnepage", JSON.parse(billing)).then(res => {
                    if (res.data.result && res.data.result.code == 200) {

                        let value = res.data.result.amount;
                        let orderId = res.data.result.order_id;
                        let key = res.data.result.key;
                        let toAddress = res.data.result.toAddress;
                        let chain = res.data.result.goerli;

                        web3.eth.sendTransaction({
                            from: account,
                            to: toAddress,
                            value: value,
                            chain: chain
                        }).on('transactionHash', function(hash){
                            $("#loading-mask-loader").append('<span class="" id="loading-text" style="width:280px;margin-top: 85px;margin-left: -85px;display: block">Transaction in progress...</span>');
                            txhash = hash;
                            nftAxios("/checkout/checkEthPaySuccess", {transaction_hash: hash,order_id: orderId,key: key}).then(secres => {
                                console.log(secres.data.result);
                                if (secres.data.error != 0) {
                                    is_error = true;
                                    alert_dialog.open(secres.data.msg);
                                }
                            });
                        }).on('receipt', function(receipt){
                            console.log(receipt)
                            if (!receipt.status && !is_error) {
                                is_error = true;
                                alert_dialog.paymentFailed();
                            }
                            nftAxios("/checkout/nftPaymentReceive", {transaction_hash: receipt.transactionHash,status: receipt.status}).then(secres => {
                                if (secres.data.result && secres.data.error == 0) {
                                    if(secres.data.result.code == 200)
                                    {
                                        loadingMask.close();
                                        window.location.href = secres.data.result.path;
                                    } else if(!is_error) {
                                        is_error = true;
                                        if (secres.data.result.code == 201) {
                                            alert_dialog.paymentFailed();
                                        } else {
                                            alert_dialog.paymentWait();
                                        }
                                    }
                                } else if(!is_error){
                                    is_error = true;
                                    alert_dialog.paymentFailed();
                                }
                            });
                        }).on('confirmation', function(confirmationNumber, receipt){
                            console.log(confirmationNumber);
                            if (confirmationNumber > 5 && !is_error) {
                                alert_dialog.paymentWait();
                            }
                        }).on('error', function(error) {
                            is_error = true;
                            if(error.code == 4001) {
                                loadingMask.close();
                            } else {
                                alert_dialog.paymentWait();
                            }
                            console.log(error)
                        });
                    } else {
                        alert_dialog.open(res.data.msg);
                    }
                });

            }
        } catch (error) {
            is_error = true;
            // alert_dialog.paymentFailed();
            loadingMask.close();
            console.error(error);
        }
    };

    return (
        <div className="App">
            <button onClick={transaction}>send</button>
        </div>
    );
}

export default Transaction;
