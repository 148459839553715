import $ from 'jquery'
let defaultInfo={
    img:"/assets/images/icon/pay_wait.png",
    title:'Connection interrupted',
    content:'Please click on browser plug-in for MetaMask connection',
    button:'View Order Details'
};
let loadingMask = {
    open : function() {
        $('#my-loading-mask').show();
    },
    close : function() {
        $('#my-loading-mask').hide();
    },
    fadeOut : function() {
        $('#my-loading-mask').fadeOut();
    },
    show: function() {
        $('#failedModal .modal-content').html(`
            <button type="button" class="close" data-dismiss="modal" id="failedModalclose" aria-label="Close"></button>
            <div class="modal-body" style="padding-top: 18px;">
                <img src="/assets/images/icon/model_tip_img.png" alt="" style="height: 45px;">
                <span>Connection interrupted</span>
                <p class="info-a" style="margin-bottom: 65px;">Please click on the browser plug-in for MetaMask connection. </p>
            </div>`);
        $('#failedModalclose').click(function() {
            $('#failedModal').hide();
        });
        $('#failedModal').show();
    }
};

export default loadingMask;
