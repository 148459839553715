import axios from "axios";
import Config from "../config/config";

async function post(url, param) {
    let result;
    await axios.post(url, param,
        {headers:{"Accept": "application/json, text/javascript, */*; q=0.01", "content-type": "multipart/form-data"}}).then(res => {
        result = res;
    }).catch(err => {
        result = err;
    });
    return result;
}

export default post;
