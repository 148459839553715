import {ethers} from 'ethers';
import {useState, useEffect} from 'react';
import web3Modal from '../helper/connect';
import nftAxios from "../helper/axios";

function Connect() {
    const [provider, setProvider] = useState();
    const [library, setLibrary] = useState();
    const [account, setAccount, ] = useState();
    const [signature, setSignature] = useState("");
    const [error, setError] = useState("");
    const [chainId, setChainId] = useState();
    const [network, setNetwork] = useState();
    const [message, setMessage] = useState("");
    const [signedMessage, setSignedMessage] = useState("");
    const [verified, setVerified] = useState();
    const [to, setTo] = useState('')

    //判断是否有钱包连接的缓存
    useEffect(() => {
        if (web3Modal.cachedProvider) {
            connectWallet();
        }
    }, []);

    useEffect(() => {
        if (provider?.on) {
            const handleAccountsChanged = (accounts) => {
                console.log("accountsChanged", accounts);
                if (accounts) setAccount(accounts[0]);
            };

            const handleDisconnect = () => {
                console.log("disconnect", error);
                disconnect();
            };

            provider.on("accountsChanged", handleAccountsChanged);
            provider.on("disconnect", handleDisconnect);

            return () => {
                if (provider.removeListener) {
                    provider.removeListener("accountsChanged", handleAccountsChanged);
                    provider.removeListener("disconnect", handleDisconnect);
                }
            };
        }
    }, [provider]);

    const disconnect = async () => {
        await web3Modal.clearCachedProvider();
        refreshState();
    };

    const refreshState = () => {
        setAccount();
        setChainId();
        setNetwork("");
        setMessage("");
        setSignature("");
        setVerified(undefined);
        setLibrary("")
        nftAxios("/login/logout" );
        window.location.reload();
    };

    const connectWallet = async () => {
        try {
            const provider = await web3Modal.connect();

            const library = new ethers.providers.Web3Provider(provider);
            const accounts = await library.listAccounts();

            const network = await library.getNetwork();
            setProvider(provider);
            setLibrary(library);
            if (accounts) {
                setAccount(accounts[0])
            }
            setNetwork(network);

            if (accounts[0]) {
                let result = nftAxios("/login/login", {hash_key: accounts[0]});

                result.then(res => {
                    if (res.data.result && res.data.result.code == 200) {
                        window.location.reload();
                    } else if(res.data.error == 10002) {
                        disconnect();
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="App">
            {!library &&
            <button onClick={connectWallet}>Connect Wallet</button>
            }
        </div>
    );
}

export default Connect;
